// CSS generated by plone's grid.plone.less/grid mixins for
// 20 columns to implement the hu base layout.

.hu-base-row {
  margin-left: -15px;
  margin-right: -15px;
  clear: both;
}
.hu-base-row::before,
.hu-base-row::after {
  content: " ";
  display: table;
}
.hu-base-row::after {
  clear: both;
}
.hu-base-col-xs-1,
.hu-base-col-sm-1,
.hu-base-col-md-1,
.hu-base-col-lg-1,
.hu-base-col-xs-2,
.hu-base-col-sm-2,
.hu-base-col-md-2,
.hu-base-col-lg-2,
.hu-base-col-xs-3,
.hu-base-col-sm-3,
.hu-base-col-md-3,
.hu-base-col-lg-3,
.hu-base-col-xs-4,
.hu-base-col-sm-4,
.hu-base-col-md-4,
.hu-base-col-lg-4,
.hu-base-col-xs-5,
.hu-base-col-sm-5,
.hu-base-col-md-5,
.hu-base-col-lg-5,
.hu-base-col-xs-6,
.hu-base-col-sm-6,
.hu-base-col-md-6,
.hu-base-col-lg-6,
.hu-base-col-xs-7,
.hu-base-col-sm-7,
.hu-base-col-md-7,
.hu-base-col-lg-7,
.hu-base-col-xs-8,
.hu-base-col-sm-8,
.hu-base-col-md-8,
.hu-base-col-lg-8,
.hu-base-col-xs-9,
.hu-base-col-sm-9,
.hu-base-col-md-9,
.hu-base-col-lg-9,
.hu-base-col-xs-10,
.hu-base-col-sm-10,
.hu-base-col-md-10,
.hu-base-col-lg-10,
.hu-base-col-xs-11,
.hu-base-col-sm-11,
.hu-base-col-md-11,
.hu-base-col-lg-11,
.hu-base-col-xs-12,
.hu-base-col-sm-12,
.hu-base-col-md-12,
.hu-base-col-lg-12,
.hu-base-col-xs-13,
.hu-base-col-sm-13,
.hu-base-col-md-13,
.hu-base-col-lg-13,
.hu-base-col-xs-14,
.hu-base-col-sm-14,
.hu-base-col-md-14,
.hu-base-col-lg-14,
.hu-base-col-xs-15,
.hu-base-col-sm-15,
.hu-base-col-md-15,
.hu-base-col-lg-15,
.hu-base-col-xs-16,
.hu-base-col-sm-16,
.hu-base-col-md-16,
.hu-base-col-lg-16,
.hu-base-col-xs-17,
.hu-base-col-sm-17,
.hu-base-col-md-17,
.hu-base-col-lg-17,
.hu-base-col-xs-18,
.hu-base-col-sm-18,
.hu-base-col-md-18,
.hu-base-col-lg-18,
.hu-base-col-xs-19,
.hu-base-col-sm-19,
.hu-base-col-md-19,
.hu-base-col-lg-19,
.hu-base-col-xs-20,
.hu-base-col-sm-20,
.hu-base-col-md-20,
.hu-base-col-lg-20 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.hu-base-col-xs-1,
.hu-base-col-xs-2,
.hu-base-col-xs-3,
.hu-base-col-xs-4,
.hu-base-col-xs-5,
.hu-base-col-xs-6,
.hu-base-col-xs-7,
.hu-base-col-xs-8,
.hu-base-col-xs-9,
.hu-base-col-xs-10,
.hu-base-col-xs-11,
.hu-base-col-xs-12,
.hu-base-col-xs-13,
.hu-base-col-xs-14,
.hu-base-col-xs-15,
.hu-base-col-xs-16,
.hu-base-col-xs-17,
.hu-base-col-xs-18,
.hu-base-col-xs-19,
.hu-base-col-xs-20 {
  float: left;
}
.hu-base-col-xs-20 {
  width: 100%;
}
.hu-base-col-xs-19 {
  width: 95%;
}
.hu-base-col-xs-18 {
  width: 90%;
}
.hu-base-col-xs-17 {
  width: 85%;
}
.hu-base-col-xs-16 {
  width: 80%;
}
.hu-base-col-xs-15 {
  width: 75%;
}
.hu-base-col-xs-14 {
  width: 70%;
}
.hu-base-col-xs-13 {
  width: 65%;
}
.hu-base-col-xs-12 {
  width: 60%;
}
.hu-base-col-xs-11 {
  width: 55%;
}
.hu-base-col-xs-10 {
  width: 50%;
}
.hu-base-col-xs-9 {
  width: 45%;
}
.hu-base-col-xs-8 {
  width: 40%;
}
.hu-base-col-xs-7 {
  width: 35%;
}
.hu-base-col-xs-6 {
  width: 30%;
}
.hu-base-col-xs-5 {
  width: 25%;
}
.hu-base-col-xs-4 {
  width: 20%;
}
.hu-base-col-xs-3 {
  width: 15%;
}
.hu-base-col-xs-2 {
  width: 10%;
}
.hu-base-col-xs-1 {
  width: 5%;
}
.hu-base-col-xs-pull-20 {
  right: 100%;
}
.hu-base-col-xs-pull-19 {
  right: 95%;
}
.hu-base-col-xs-pull-18 {
  right: 90%;
}
.hu-base-col-xs-pull-17 {
  right: 85%;
}
.hu-base-col-xs-pull-16 {
  right: 80%;
}
.hu-base-col-xs-pull-15 {
  right: 75%;
}
.hu-base-col-xs-pull-14 {
  right: 70%;
}
.hu-base-col-xs-pull-13 {
  right: 65%;
}
.hu-base-col-xs-pull-12 {
  right: 60%;
}
.hu-base-col-xs-pull-11 {
  right: 55%;
}
.hu-base-col-xs-pull-10 {
  right: 50%;
}
.hu-base-col-xs-pull-9 {
  right: 45%;
}
.hu-base-col-xs-pull-8 {
  right: 40%;
}
.hu-base-col-xs-pull-7 {
  right: 35%;
}
.hu-base-col-xs-pull-6 {
  right: 30%;
}
.hu-base-col-xs-pull-5 {
  right: 25%;
}
.hu-base-col-xs-pull-4 {
  right: 20%;
}
.hu-base-col-xs-pull-3 {
  right: 15%;
}
.hu-base-col-xs-pull-2 {
  right: 10%;
}
.hu-base-col-xs-pull-1 {
  right: 5%;
}
.hu-base-col-xs-pull-0 {
  right: auto;
}
.hu-base-col-xs-push-20 {
  left: 100%;
}
.hu-base-col-xs-push-19 {
  left: 95%;
}
.hu-base-col-xs-push-18 {
  left: 90%;
}
.hu-base-col-xs-push-17 {
  left: 85%;
}
.hu-base-col-xs-push-16 {
  left: 80%;
}
.hu-base-col-xs-push-15 {
  left: 75%;
}
.hu-base-col-xs-push-14 {
  left: 70%;
}
.hu-base-col-xs-push-13 {
  left: 65%;
}
.hu-base-col-xs-push-12 {
  left: 60%;
}
.hu-base-col-xs-push-11 {
  left: 55%;
}
.hu-base-col-xs-push-10 {
  left: 50%;
}
.hu-base-col-xs-push-9 {
  left: 45%;
}
.hu-base-col-xs-push-8 {
  left: 40%;
}
.hu-base-col-xs-push-7 {
  left: 35%;
}
.hu-base-col-xs-push-6 {
  left: 30%;
}
.hu-base-col-xs-push-5 {
  left: 25%;
}
.hu-base-col-xs-push-4 {
  left: 20%;
}
.hu-base-col-xs-push-3 {
  left: 15%;
}
.hu-base-col-xs-push-2 {
  left: 10%;
}
.hu-base-col-xs-push-1 {
  left: 5%;
}
.hu-base-col-xs-push-0 {
  left: auto;
}
.hu-base-col-xs-offset-20 {
  margin-left: 100%;
}
.hu-base-col-xs-offset-19 {
  margin-left: 95%;
}
.hu-base-col-xs-offset-18 {
  margin-left: 90%;
}
.hu-base-col-xs-offset-17 {
  margin-left: 85%;
}
.hu-base-col-xs-offset-16 {
  margin-left: 80%;
}
.hu-base-col-xs-offset-15 {
  margin-left: 75%;
}
.hu-base-col-xs-offset-14 {
  margin-left: 70%;
}
.hu-base-col-xs-offset-13 {
  margin-left: 65%;
}
.hu-base-col-xs-offset-12 {
  margin-left: 60%;
}
.hu-base-col-xs-offset-11 {
  margin-left: 55%;
}
.hu-base-col-xs-offset-10 {
  margin-left: 50%;
}
.hu-base-col-xs-offset-9 {
  margin-left: 45%;
}
.hu-base-col-xs-offset-8 {
  margin-left: 40%;
}
.hu-base-col-xs-offset-7 {
  margin-left: 35%;
}
.hu-base-col-xs-offset-6 {
  margin-left: 30%;
}
.hu-base-col-xs-offset-5 {
  margin-left: 25%;
}
.hu-base-col-xs-offset-4 {
  margin-left: 20%;
}
.hu-base-col-xs-offset-3 {
  margin-left: 15%;
}
.hu-base-col-xs-offset-2 {
  margin-left: 10%;
}
.hu-base-col-xs-offset-1 {
  margin-left: 5%;
}
.hu-base-col-xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 768px) {
  .hu-base-col-sm-1,
  .hu-base-col-sm-2,
  .hu-base-col-sm-3,
  .hu-base-col-sm-4,
  .hu-base-col-sm-5,
  .hu-base-col-sm-6,
  .hu-base-col-sm-7,
  .hu-base-col-sm-8,
  .hu-base-col-sm-9,
  .hu-base-col-sm-10,
  .hu-base-col-sm-11,
  .hu-base-col-sm-12,
  .hu-base-col-sm-13,
  .hu-base-col-sm-14,
  .hu-base-col-sm-15,
  .hu-base-col-sm-16,
  .hu-base-col-sm-17,
  .hu-base-col-sm-18,
  .hu-base-col-sm-19,
  .hu-base-col-sm-20 {
    float: left;
  }
  .hu-base-col-sm-20 {
    width: 100%;
  }
  .hu-base-col-sm-19 {
    width: 95%;
  }
  .hu-base-col-sm-18 {
    width: 90%;
  }
  .hu-base-col-sm-17 {
    width: 85%;
  }
  .hu-base-col-sm-16 {
    width: 80%;
  }
  .hu-base-col-sm-15 {
    width: 75%;
  }
  .hu-base-col-sm-14 {
    width: 70%;
  }
  .hu-base-col-sm-13 {
    width: 65%;
  }
  .hu-base-col-sm-12 {
    width: 60%;
  }
  .hu-base-col-sm-11 {
    width: 55%;
  }
  .hu-base-col-sm-10 {
    width: 50%;
  }
  .hu-base-col-sm-9 {
    width: 45%;
  }
  .hu-base-col-sm-8 {
    width: 40%;
  }
  .hu-base-col-sm-7 {
    width: 35%;
  }
  .hu-base-col-sm-6 {
    width: 30%;
  }
  .hu-base-col-sm-5 {
    width: 25%;
  }
  .hu-base-col-sm-4 {
    width: 20%;
  }
  .hu-base-col-sm-3 {
    width: 15%;
  }
  .hu-base-col-sm-2 {
    width: 10%;
  }
  .hu-base-col-sm-1 {
    width: 5%;
  }
  .hu-base-col-sm-pull-20 {
    right: 100%;
  }
  .hu-base-col-sm-pull-19 {
    right: 95%;
  }
  .hu-base-col-sm-pull-18 {
    right: 90%;
  }
  .hu-base-col-sm-pull-17 {
    right: 85%;
  }
  .hu-base-col-sm-pull-16 {
    right: 80%;
  }
  .hu-base-col-sm-pull-15 {
    right: 75%;
  }
  .hu-base-col-sm-pull-14 {
    right: 70%;
  }
  .hu-base-col-sm-pull-13 {
    right: 65%;
  }
  .hu-base-col-sm-pull-12 {
    right: 60%;
  }
  .hu-base-col-sm-pull-11 {
    right: 55%;
  }
  .hu-base-col-sm-pull-10 {
    right: 50%;
  }
  .hu-base-col-sm-pull-9 {
    right: 45%;
  }
  .hu-base-col-sm-pull-8 {
    right: 40%;
  }
  .hu-base-col-sm-pull-7 {
    right: 35%;
  }
  .hu-base-col-sm-pull-6 {
    right: 30%;
  }
  .hu-base-col-sm-pull-5 {
    right: 25%;
  }
  .hu-base-col-sm-pull-4 {
    right: 20%;
  }
  .hu-base-col-sm-pull-3 {
    right: 15%;
  }
  .hu-base-col-sm-pull-2 {
    right: 10%;
  }
  .hu-base-col-sm-pull-1 {
    right: 5%;
  }
  .hu-base-col-sm-pull-0 {
    right: auto;
  }
  .hu-base-col-sm-push-20 {
    left: 100%;
  }
  .hu-base-col-sm-push-19 {
    left: 95%;
  }
  .hu-base-col-sm-push-18 {
    left: 90%;
  }
  .hu-base-col-sm-push-17 {
    left: 85%;
  }
  .hu-base-col-sm-push-16 {
    left: 80%;
  }
  .hu-base-col-sm-push-15 {
    left: 75%;
  }
  .hu-base-col-sm-push-14 {
    left: 70%;
  }
  .hu-base-col-sm-push-13 {
    left: 65%;
  }
  .hu-base-col-sm-push-12 {
    left: 60%;
  }
  .hu-base-col-sm-push-11 {
    left: 55%;
  }
  .hu-base-col-sm-push-10 {
    left: 50%;
  }
  .hu-base-col-sm-push-9 {
    left: 45%;
  }
  .hu-base-col-sm-push-8 {
    left: 40%;
  }
  .hu-base-col-sm-push-7 {
    left: 35%;
  }
  .hu-base-col-sm-push-6 {
    left: 30%;
  }
  .hu-base-col-sm-push-5 {
    left: 25%;
  }
  .hu-base-col-sm-push-4 {
    left: 20%;
  }
  .hu-base-col-sm-push-3 {
    left: 15%;
  }
  .hu-base-col-sm-push-2 {
    left: 10%;
  }
  .hu-base-col-sm-push-1 {
    left: 5%;
  }
  .hu-base-col-sm-push-0 {
    left: auto;
  }
  .hu-base-col-sm-offset-20 {
    margin-left: 100%;
  }
  .hu-base-col-sm-offset-19 {
    margin-left: 95%;
  }
  .hu-base-col-sm-offset-18 {
    margin-left: 90%;
  }
  .hu-base-col-sm-offset-17 {
    margin-left: 85%;
  }
  .hu-base-col-sm-offset-16 {
    margin-left: 80%;
  }
  .hu-base-col-sm-offset-15 {
    margin-left: 75%;
  }
  .hu-base-col-sm-offset-14 {
    margin-left: 70%;
  }
  .hu-base-col-sm-offset-13 {
    margin-left: 65%;
  }
  .hu-base-col-sm-offset-12 {
    margin-left: 60%;
  }
  .hu-base-col-sm-offset-11 {
    margin-left: 55%;
  }
  .hu-base-col-sm-offset-10 {
    margin-left: 50%;
  }
  .hu-base-col-sm-offset-9 {
    margin-left: 45%;
  }
  .hu-base-col-sm-offset-8 {
    margin-left: 40%;
  }
  .hu-base-col-sm-offset-7 {
    margin-left: 35%;
  }
  .hu-base-col-sm-offset-6 {
    margin-left: 30%;
  }
  .hu-base-col-sm-offset-5 {
    margin-left: 25%;
  }
  .hu-base-col-sm-offset-4 {
    margin-left: 20%;
  }
  .hu-base-col-sm-offset-3 {
    margin-left: 15%;
  }
  .hu-base-col-sm-offset-2 {
    margin-left: 10%;
  }
  .hu-base-col-sm-offset-1 {
    margin-left: 5%;
  }
  .hu-base-col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .hu-base-col-md-1,
  .hu-base-col-md-2,
  .hu-base-col-md-3,
  .hu-base-col-md-4,
  .hu-base-col-md-5,
  .hu-base-col-md-6,
  .hu-base-col-md-7,
  .hu-base-col-md-8,
  .hu-base-col-md-9,
  .hu-base-col-md-10,
  .hu-base-col-md-11,
  .hu-base-col-md-12,
  .hu-base-col-md-13,
  .hu-base-col-md-14,
  .hu-base-col-md-15,
  .hu-base-col-md-16,
  .hu-base-col-md-17,
  .hu-base-col-md-18,
  .hu-base-col-md-19,
  .hu-base-col-md-20 {
    float: left;
  }
  .hu-base-col-md-20 {
    width: 100%;
  }
  .hu-base-col-md-19 {
    width: 95%;
  }
  .hu-base-col-md-18 {
    width: 90%;
  }
  .hu-base-col-md-17 {
    width: 85%;
  }
  .hu-base-col-md-16 {
    width: 80%;
  }
  .hu-base-col-md-15 {
    width: 75%;
  }
  .hu-base-col-md-14 {
    width: 70%;
  }
  .hu-base-col-md-13 {
    width: 65%;
  }
  .hu-base-col-md-12 {
    width: 60%;
  }
  .hu-base-col-md-11 {
    width: 55%;
  }
  .hu-base-col-md-10 {
    width: 50%;
  }
  .hu-base-col-md-9 {
    width: 45%;
  }
  .hu-base-col-md-8 {
    width: 40%;
  }
  .hu-base-col-md-7 {
    width: 35%;
  }
  .hu-base-col-md-6 {
    width: 30%;
  }
  .hu-base-col-md-5 {
    width: 25%;
  }
  .hu-base-col-md-4 {
    width: 20%;
  }
  .hu-base-col-md-3 {
    width: 15%;
  }
  .hu-base-col-md-2 {
    width: 10%;
  }
  .hu-base-col-md-1 {
    width: 5%;
  }
  .hu-base-col-md-pull-20 {
    right: 100%;
  }
  .hu-base-col-md-pull-19 {
    right: 95%;
  }
  .hu-base-col-md-pull-18 {
    right: 90%;
  }
  .hu-base-col-md-pull-17 {
    right: 85%;
  }
  .hu-base-col-md-pull-16 {
    right: 80%;
  }
  .hu-base-col-md-pull-15 {
    right: 75%;
  }
  .hu-base-col-md-pull-14 {
    right: 70%;
  }
  .hu-base-col-md-pull-13 {
    right: 65%;
  }
  .hu-base-col-md-pull-12 {
    right: 60%;
  }
  .hu-base-col-md-pull-11 {
    right: 55%;
  }
  .hu-base-col-md-pull-10 {
    right: 50%;
  }
  .hu-base-col-md-pull-9 {
    right: 45%;
  }
  .hu-base-col-md-pull-8 {
    right: 40%;
  }
  .hu-base-col-md-pull-7 {
    right: 35%;
  }
  .hu-base-col-md-pull-6 {
    right: 30%;
  }
  .hu-base-col-md-pull-5 {
    right: 25%;
  }
  .hu-base-col-md-pull-4 {
    right: 20%;
  }
  .hu-base-col-md-pull-3 {
    right: 15%;
  }
  .hu-base-col-md-pull-2 {
    right: 10%;
  }
  .hu-base-col-md-pull-1 {
    right: 5%;
  }
  .hu-base-col-md-pull-0 {
    right: auto;
  }
  .hu-base-col-md-push-20 {
    left: 100%;
  }
  .hu-base-col-md-push-19 {
    left: 95%;
  }
  .hu-base-col-md-push-18 {
    left: 90%;
  }
  .hu-base-col-md-push-17 {
    left: 85%;
  }
  .hu-base-col-md-push-16 {
    left: 80%;
  }
  .hu-base-col-md-push-15 {
    left: 75%;
  }
  .hu-base-col-md-push-14 {
    left: 70%;
  }
  .hu-base-col-md-push-13 {
    left: 65%;
  }
  .hu-base-col-md-push-12 {
    left: 60%;
  }
  .hu-base-col-md-push-11 {
    left: 55%;
  }
  .hu-base-col-md-push-10 {
    left: 50%;
  }
  .hu-base-col-md-push-9 {
    left: 45%;
  }
  .hu-base-col-md-push-8 {
    left: 40%;
  }
  .hu-base-col-md-push-7 {
    left: 35%;
  }
  .hu-base-col-md-push-6 {
    left: 30%;
  }
  .hu-base-col-md-push-5 {
    left: 25%;
  }
  .hu-base-col-md-push-4 {
    left: 20%;
  }
  .hu-base-col-md-push-3 {
    left: 15%;
  }
  .hu-base-col-md-push-2 {
    left: 10%;
  }
  .hu-base-col-md-push-1 {
    left: 5%;
  }
  .hu-base-col-md-push-0 {
    left: auto;
  }
  .hu-base-col-md-offset-20 {
    margin-left: 100%;
  }
  .hu-base-col-md-offset-19 {
    margin-left: 95%;
  }
  .hu-base-col-md-offset-18 {
    margin-left: 90%;
  }
  .hu-base-col-md-offset-17 {
    margin-left: 85%;
  }
  .hu-base-col-md-offset-16 {
    margin-left: 80%;
  }
  .hu-base-col-md-offset-15 {
    margin-left: 75%;
  }
  .hu-base-col-md-offset-14 {
    margin-left: 70%;
  }
  .hu-base-col-md-offset-13 {
    margin-left: 65%;
  }
  .hu-base-col-md-offset-12 {
    margin-left: 60%;
  }
  .hu-base-col-md-offset-11 {
    margin-left: 55%;
  }
  .hu-base-col-md-offset-10 {
    margin-left: 50%;
  }
  .hu-base-col-md-offset-9 {
    margin-left: 45%;
  }
  .hu-base-col-md-offset-8 {
    margin-left: 40%;
  }
  .hu-base-col-md-offset-7 {
    margin-left: 35%;
  }
  .hu-base-col-md-offset-6 {
    margin-left: 30%;
  }
  .hu-base-col-md-offset-5 {
    margin-left: 25%;
  }
  .hu-base-col-md-offset-4 {
    margin-left: 20%;
  }
  .hu-base-col-md-offset-3 {
    margin-left: 15%;
  }
  .hu-base-col-md-offset-2 {
    margin-left: 10%;
  }
  .hu-base-col-md-offset-1 {
    margin-left: 5%;
  }
  .hu-base-col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1280px) {
  .hu-base-col-lg-1,
  .hu-base-col-lg-2,
  .hu-base-col-lg-3,
  .hu-base-col-lg-4,
  .hu-base-col-lg-5,
  .hu-base-col-lg-6,
  .hu-base-col-lg-7,
  .hu-base-col-lg-8,
  .hu-base-col-lg-9,
  .hu-base-col-lg-10,
  .hu-base-col-lg-11,
  .hu-base-col-lg-12,
  .hu-base-col-lg-13,
  .hu-base-col-lg-14,
  .hu-base-col-lg-15,
  .hu-base-col-lg-16,
  .hu-base-col-lg-17,
  .hu-base-col-lg-18,
  .hu-base-col-lg-19,
  .hu-base-col-lg-20 {
    float: left;
  }
  .hu-base-col-lg-20 {
    width: 100%;
  }
  .hu-base-col-lg-19 {
    width: 95%;
  }
  .hu-base-col-lg-18 {
    width: 90%;
  }
  .hu-base-col-lg-17 {
    width: 85%;
  }
  .hu-base-col-lg-16 {
    width: 80%;
  }
  .hu-base-col-lg-15 {
    width: 75%;
  }
  .hu-base-col-lg-14 {
    width: 70%;
  }
  .hu-base-col-lg-13 {
    width: 65%;
  }
  .hu-base-col-lg-12 {
    width: 60%;
  }
  .hu-base-col-lg-11 {
    width: 55%;
  }
  .hu-base-col-lg-10 {
    width: 50%;
  }
  .hu-base-col-lg-9 {
    width: 45%;
  }
  .hu-base-col-lg-8 {
    width: 40%;
  }
  .hu-base-col-lg-7 {
    width: 35%;
  }
  .hu-base-col-lg-6 {
    width: 30%;
  }
  .hu-base-col-lg-5 {
    width: 25%;
  }
  .hu-base-col-lg-4 {
    width: 20%;
  }
  .hu-base-col-lg-3 {
    width: 15%;
  }
  .hu-base-col-lg-2 {
    width: 10%;
  }
  .hu-base-col-lg-1 {
    width: 5%;
  }
  .hu-base-col-lg-pull-20 {
    right: 100%;
  }
  .hu-base-col-lg-pull-19 {
    right: 95%;
  }
  .hu-base-col-lg-pull-18 {
    right: 90%;
  }
  .hu-base-col-lg-pull-17 {
    right: 85%;
  }
  .hu-base-col-lg-pull-16 {
    right: 80%;
  }
  .hu-base-col-lg-pull-15 {
    right: 75%;
  }
  .hu-base-col-lg-pull-14 {
    right: 70%;
  }
  .hu-base-col-lg-pull-13 {
    right: 65%;
  }
  .hu-base-col-lg-pull-12 {
    right: 60%;
  }
  .hu-base-col-lg-pull-11 {
    right: 55%;
  }
  .hu-base-col-lg-pull-10 {
    right: 50%;
  }
  .hu-base-col-lg-pull-9 {
    right: 45%;
  }
  .hu-base-col-lg-pull-8 {
    right: 40%;
  }
  .hu-base-col-lg-pull-7 {
    right: 35%;
  }
  .hu-base-col-lg-pull-6 {
    right: 30%;
  }
  .hu-base-col-lg-pull-5 {
    right: 25%;
  }
  .hu-base-col-lg-pull-4 {
    right: 20%;
  }
  .hu-base-col-lg-pull-3 {
    right: 15%;
  }
  .hu-base-col-lg-pull-2 {
    right: 10%;
  }
  .hu-base-col-lg-pull-1 {
    right: 5%;
  }
  .hu-base-col-lg-pull-0 {
    right: auto;
  }
  .hu-base-col-lg-push-20 {
    left: 100%;
  }
  .hu-base-col-lg-push-19 {
    left: 95%;
  }
  .hu-base-col-lg-push-18 {
    left: 90%;
  }
  .hu-base-col-lg-push-17 {
    left: 85%;
  }
  .hu-base-col-lg-push-16 {
    left: 80%;
  }
  .hu-base-col-lg-push-15 {
    left: 75%;
  }
  .hu-base-col-lg-push-14 {
    left: 70%;
  }
  .hu-base-col-lg-push-13 {
    left: 65%;
  }
  .hu-base-col-lg-push-12 {
    left: 60%;
  }
  .hu-base-col-lg-push-11 {
    left: 55%;
  }
  .hu-base-col-lg-push-10 {
    left: 50%;
  }
  .hu-base-col-lg-push-9 {
    left: 45%;
  }
  .hu-base-col-lg-push-8 {
    left: 40%;
  }
  .hu-base-col-lg-push-7 {
    left: 35%;
  }
  .hu-base-col-lg-push-6 {
    left: 30%;
  }
  .hu-base-col-lg-push-5 {
    left: 25%;
  }
  .hu-base-col-lg-push-4 {
    left: 20%;
  }
  .hu-base-col-lg-push-3 {
    left: 15%;
  }
  .hu-base-col-lg-push-2 {
    left: 10%;
  }
  .hu-base-col-lg-push-1 {
    left: 5%;
  }
  .hu-base-col-lg-push-0 {
    left: auto;
  }
  .hu-base-col-lg-offset-20 {
    margin-left: 100%;
  }
  .hu-base-col-lg-offset-19 {
    margin-left: 95%;
  }
  .hu-base-col-lg-offset-18 {
    margin-left: 90%;
  }
  .hu-base-col-lg-offset-17 {
    margin-left: 85%;
  }
  .hu-base-col-lg-offset-16 {
    margin-left: 80%;
  }
  .hu-base-col-lg-offset-15 {
    margin-left: 75%;
  }
  .hu-base-col-lg-offset-14 {
    margin-left: 70%;
  }
  .hu-base-col-lg-offset-13 {
    margin-left: 65%;
  }
  .hu-base-col-lg-offset-12 {
    margin-left: 60%;
  }
  .hu-base-col-lg-offset-11 {
    margin-left: 55%;
  }
  .hu-base-col-lg-offset-10 {
    margin-left: 50%;
  }
  .hu-base-col-lg-offset-9 {
    margin-left: 45%;
  }
  .hu-base-col-lg-offset-8 {
    margin-left: 40%;
  }
  .hu-base-col-lg-offset-7 {
    margin-left: 35%;
  }
  .hu-base-col-lg-offset-6 {
    margin-left: 30%;
  }
  .hu-base-col-lg-offset-5 {
    margin-left: 25%;
  }
  .hu-base-col-lg-offset-4 {
    margin-left: 20%;
  }
  .hu-base-col-lg-offset-3 {
    margin-left: 15%;
  }
  .hu-base-col-lg-offset-2 {
    margin-left: 10%;
  }
  .hu-base-col-lg-offset-1 {
    margin-left: 5%;
  }
  .hu-base-col-lg-offset-0 {
    margin-left: 0%;
  }
}
