#portal-footer-wrapper {
  background-color: transparent;
  padding: 0;
}

#portal-footer {
  /*border-top: 1px solid @hu-footer-grey;
  padding-top: 1em;*/
  padding-bottom: 1em;
  color: @hu-footer-grey;

  .documentActions {
    float: right;
  }

  #footer-left-container {
    @media (max-width: 767.98px) {
      padding: 0 1rem;
	  .share_link_menu {
		margin-left: 0;
	  }
      .share_link_menu li {
        margin: 0 4% 0 0;
      }
    }
  }
  #footer-right-container {
    margin-left: 0;

    .impressum,
    .photo_credits,
    .datenschutz,
    .barrierefreiheit {
      float: right;
      font-size: 80%;
    }
	.photo_credits,
    .datenschutz,
    .barrierefreiheit {
      margin-left: 2.5%;
    }
  }
  .subcolumns { padding: .6em 2em; }
  @media (min-width: 768px) {
    #CookieConsent {
	  width: 99.9em;
    }
    .subcolumns {
      padding: 1em 23%;
    }
  }

  #footer-analytics {
    padding-left: 0;
    padding-right: 0;
  }
}

#portal-anontools {
	float: left !important;
	font-size: 80%;
}

#portal-anontools ul {
    padding: 0 !important;
}

#portal-anontools li {
	margin: 0 0 1em 0 !important;
}

.documentByLine { margin-bottom: 0; }

div.footer p { display: none; }

@media only screen and (min-width: @plone-grid-float-breakpoint) {
	.newsletter-registration {
		padding-left: 20%;
		padding-right: 20%;
	}
}
/*
#text5185435 {
    width: 66%;
    border: none;
    padding-left: 1em;
    font-size: 100%;
	display: inline;
}
body.plone-toolbar-expanded #newsletter-registration {
	display: none;
}
*/
