/* humboldt.theme */
/* header styles */

header.no-folder-image #portal-header #folder_image { visibility: hidden; }

#portal-header {
  height: 139px;
  margin: 0;
  display: flex;
  align-items: center;

  #portal-logo {
    flex-grow: 1;
    text-align: right;
	margin-right: -0.5%;
	display: flex;
	align-self: flex-end;
	justify-content: flex-end;
	
	img {
	  height: 8.5em;
	  padding: 0;
	  margin: auto auto .5em 0;
	}
  }

  @media (min-width: @plone-screen-sm-min) {
    .smallscreen.humboldt-logo {
      display: none;
    }
  }

  @media (max-width: @plone-screen-xs-max) {
    #portal-logo {
      display: none;
    }
  }

  #frontpageslider {
    #sliderbox {
      width: 100%;
      padding: 0;
    }
  }

  .portal-title.frontpageslider.frame .portal-title.frontpageslider {
    // This is copied to the left column of the header
    display: none;
  }

  /* @group Bildmotiv / folder_image / ehemals lead-content  */
  #folder_image {
    display: flex;
    align-self: flex-end;
    width: 28.13em;
	margin-left: -9px;
	position: relative;

    a.edit-link {
      position: absolute;
      background-color: white;
      padding: 0 0.2em;
    }

    img {
      background-color: #036;
      width: 100%;
      height: 100%;
    }

	.bigsliderpage & {
	  visibility: hidden;
	}
	@media only screen and (max-width: @plone-grid-float-breakpoint) {
		.bigsliderpage & {
		  display: none;
		}
	}
  }

  #folder_image_uhb {
    position: absolute;
    height: 20px;
    width: 20px;
    bottom: 0;
    right: 0;
  }

  #folder_image_uhb img {
    position: absolute;
    height: 20px;
    width: 20px;
    bottom: 0;
    background: transparent;
  }

  #folder_image_uhb div {
    display: none;
  }

  #folder_image_uhb:hover div {
    display: inline;
    width: auto;
    max-width: 360px;
    /*overflow: hidden;*/
    position: absolute;
    background-color: #e0e0d1;
    color: #666666;
    left: 20px;
    top: 10px;
    padding: .2rem .6rem;
    z-index: 91;
    white-space: nowrap;
  }
}
